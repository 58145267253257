/* NotoSans */
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 100;
    src: url('/fonts/NotoSans/NotoSansKR-Thin.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Thin.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Thin.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 200;
    src: url('/fonts/NotoSans/NotoSansKR-Light.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Light.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Light.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 300;
    src: url('/fonts/NotoSans/NotoSansKR-Light.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Light.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Light.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 400;
    src: url('/fonts/NotoSans/NotoSansKR-Regular.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Regular.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Regular.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: normal;
    src: url('/fonts/NotoSans/NotoSansKR-Regular.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Regular.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Regular.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 500;
    src: url('/fonts/NotoSans/NotoSansKR-Medium.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Medium.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Medium.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 600;
    src: url('/fonts/NotoSans/NotoSansKR-Medium.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Medium.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Medium.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 700;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 800;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: bold;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 900;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Black.otf') format('opentype');
    font-display: fallback;
}

/* Roboto */
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 100;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Thin.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Thin.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 200;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 300;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 400;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: normal;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 500;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 600;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 700;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 800;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: bold;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-display: fallback;
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 900;
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+002C;
    src: url('/fonts/Roboto/Roboto-Black.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Black.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-display: fallback;
}

/* ForceNotoSans */
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 100;
    src: url('/fonts/NotoSans/NotoSansKR-Thin.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Thin.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Thin.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 200;
    src: url('/fonts/NotoSans/NotoSansKR-Light.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Light.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Light.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 300;
    src: url('/fonts/NotoSans/NotoSansKR-Light.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Light.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Light.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 400;
    src: url('/fonts/NotoSans/NotoSansKR-Regular.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Regular.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Regular.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: normal;
    src: url('/fonts/NotoSans/NotoSansKR-Regular.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Regular.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Regular.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 500;
    src: url('/fonts/NotoSans/NotoSansKR-Medium.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Medium.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Medium.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 600;
    src: url('/fonts/NotoSans/NotoSansKR-Medium.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Medium.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Medium.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 700;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 800;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: bold;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Bold.otf') format('opentype');
    display: fallback;
}
@font-face {
    font-family: 'ForceNotoSans';
    font-weight: 900;
    src: url('/fonts/NotoSans/NotoSansKR-Bold.woff2') format('woff2'),
        url('/fonts/NotoSans/NotoSansKR-Bold.woff') format('woff'),
        url('/fonts/NotoSans/NotoSansKR-Black.otf') format('opentype');
    display: fallback;
}

/* ForceRoboto */
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 100;
    src: url('/fonts/Roboto/Roboto-Thin.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Thin.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 200;
    src: url('/fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 300;
    src: url('/fonts/Roboto/Roboto-Light.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 400;
    src: url('/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: normal;
    src: url('/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 500;
    src: url('/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 600;
    src: url('/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('/fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 700;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 800;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: bold;
    src: url('/fonts/Roboto/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'ForceRoboto';
    font-weight: 900;
    src: url('/fonts/Roboto/Roboto-Black.woff2') format('woff2'), url('/fonts/Roboto/Roboto-Black.woff') format('woff'),
        url('/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    display: fallback;
}
@font-face {
    font-family: 'Y Clover TTF';
    src: url('/fonts/YClover/YClover TTF-Bold.ttf') format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: 'Y Clover TTF';
    src: url('/fonts/YClover/YClover TTF-Regular.ttf') format('truetype');
    font-weight: normal;
}
