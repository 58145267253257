html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: NotoSansKR;
    white-space: pre-wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dedede;
}

#__next {
    width: 100%;
    height: 100%;
    background-color: #fff;
    @media (min-width: 1280px) {
        max-width: 500px;
    }
}

a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

input {
    margin: 0;
    padding: 0;
    font-family: NotoSansKR;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    -webkit-border-radius: 0;
}

textarea {
    font-family: NotoSansKR;
    -webkit-tap-highlight-color: transparent;
}

button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-family: NotoSansKR;
    -webkit-tap-highlight-color: transparent;
}

label {
    -webkit-tap-highlight-color: transparent;
}

.pointer {
    cursor: pointer;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
